import {
  Box,
  Heading,
  Button,
  Image,
  Text,
  VStack
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import React from "react";
import Layout from '../components/Layout';
import Wrapper from '../components/atoms/Wrapper';
import boxSrc from '../assets/box-terms-conditions.png';

const TermsConditionsPage = ({ location }) => {
  return (
    <Layout 
      location={location}
      seo={{
        meta_title: 'Terms & Conditions'
      }}>
        <Box>
          <Wrapper>
            <Box textAlign="center" mb="6">
              <Heading size="md" mb="0">
                Terms &amp; Conditions
              </Heading>
            </Box>
            <Box position="relative" width="108%" ml="-4%" mb="6">
              <Image w="100%" src={boxSrc}/>
              <Box 
                position="absolute" 
                top="0" 
                left="0" 
                w="100%" 
                h="100%" 
                pl="12" 
                pr="8"
                py="16" 
                fontSize="xs"
                sx={{
                  '.simplebar-track.simplebar-vertical': {
                    width: '0.125rem',
                    background: 'gray.700'
                  },
                  '.simplebar-scrollbar::before': {
                    width: '100%',
                    height: '100%',
                    backgroundColor: "green.500",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: '1 !important',
                    borderRadius: 0
                  }
                }}>
                <SimpleBar style={{ maxHeight: 380 }}>
                  <Box pr="8" pb="4">
                    <Heading size="md" mb="6">
                      ZX COMPETITION – CONSENT &amp; RELEASE OF LIABILITY
                    </Heading>
                    <VStack spacing="4" align="flex-start">
                      <Heading size="md" fontWeight="normal" mb="0">1. Release, waiver and indemnity</Heading>
                      <Text>(I.1) Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.</Text>
                      <Text>(I.2) Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.</Text>
                      <Text>(I.2) Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.</Text>
                      <Text>(I.2) Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.</Text>
                      <Text>(I.2) Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.</Text>
                    </VStack>
                  </Box>
                </SimpleBar>
              </Box>
            </Box>
            <Button as={GatsbyLink} to="/" variant="primary" w="100%">
              Go Back
            </Button>
          </Wrapper>
        </Box>
    </Layout>
  );
}

export default TermsConditionsPage
